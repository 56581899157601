<template>
  <v-card class="pa-4" :class="darkmode || 'grey lighten-5'" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn depressed @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </div>
    <div :class="darkmode || 'white'" class="pa-3">
      <div class="d-flex mb-2 align-center">
        <div class="d-inline-block">
          {{ $t("e_test.question_name") }}
          <span class="font-weight-bold divider pr-2">
            : {{ dataTable.header.etest_title }}
          </span>
          <span class="font-weight-bold ml-2">
            {{ dataTable.header.count_remedy }} {{ $t("e_test.remedy") }}
          </span>
        </div>
        <v-spacer />
        <v-btn
          depressed
          class="gradient-primary mr-2"
          dark
          @click="toggleAddEtest()"
        >
          {{ $t("e_test.added_remedy") }}
          <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
        </v-btn>
        <v-text-field
          v-model="table.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          style="max-width: 250px"
          prepend-inner-icon="mdi-magnify"
          class="d-inline-block"
        />
        <v-tooltip v-if="dataTableMultiple.length !== 0" bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="error white--text ml-2"
              @click="deleteConfirm = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("e_test.delete_remedy") }}</span>
        </v-tooltip>
      </div>
      <v-data-table
        v-model="dataTableMultiple"
        :headers="tableHeaders"
        :items="dataTable.body.data"
        :page.sync="table.page"
        :items-per-page="table.limit"
        :loading="table.loading"
        show-select
        item-key="etest_class"
        hide-default-footer
        class="elevation-0"
      >
        <template #item.data-table-select="{ item, isSelected, select }">
          <!-- PUBLISH || DRAFT -->
          <v-simple-checkbox
            v-if="item.status === 'DRAFT'"
            :value="isSelected"
            @input="select($event)"
          />
          <v-icon v-else color="grey lighten-1">mdi-square-outline</v-icon>
        </template>
        <template #item.number="{item}">
          {{ dataTable.body.data.indexOf(item) + dataTable.body.from - 1 + 1 }}
        </template>
        <template #item.hours="{item}">
          {{ getHours(item.start) }}
        </template>
        <template #item.start="{item}">
          {{ getDate(item.start, true) }}
        </template>
        <template #item.end="{item}">
          {{ getDate(item.end, true) }}
        </template>
        <template #item.created_at="{item}">
          {{ getDate(item.created_at, true) }}
        </template>
        <template #item.duration="{item}">
          {{ item.duration + " " + $t("app.minutes") }}
        </template>
        <template #item.action="{item}">
          <v-menu transition="slide-x-transition" bottom right rounded="lg">
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="table.loading"
                fab
                small
                depressed
                icon
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menu, i) in detailMenu"
                :key="i"
                :disabled="
                  item.status === 'PUBLISH' && menu.icon === 'mdi-delete'
                    ? true
                    : false
                "
                @click="getRoute(item, i)"
              >
                <v-list-item-title
                  ><v-icon
                    :color="
                      item.status === 'PUBLISH' && menu.icon === 'mdi-delete'
                        ? 'grey'
                        : 'primary'
                    "
                    class="mr-2"
                    >{{
                      i === 2 && item.status === "PUBLISH"
                        ? "mdi-close-circle-outline"
                        : menu.icon
                    }}</v-icon
                  >
                  {{
                    i === 2 && item.status === "PUBLISH"
                      ? $t("app.cancel") + " " + menu.name
                      : menu.name
                  }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <Pagination
        :length="dataTable.body.last_page"
        :total="dataTable.body.total"
        :current-page="table.page"
        :limit="table.limit"
        :handler="paginateChange"
      />
    </div>
    <AddEtest
      :open="openAddRemedy"
      :info="dataTable"
      :view="viewRemedy"
      remedy
      @close="toggleAddEtest"
    />
    <ModalConfirm
      :close="() => (this.deleteConfirm = false)"
      :loading="loadingDelete"
      :isOpen="deleteConfirm"
      :save="deleteRemedy"
      :content="$t('e_test.msg_delete_remedy')"
      :title="$t('e_test.delete_remedy')"
    />
    <ModalConfirm
      :title="titleModal"
      :close="() => (this.modalConfirm = false)"
      :loading="loadingConfirm"
      :isOpen="modalConfirm"
      :save="savePublish"
    >
      {{ contentModal }}
    </ModalConfirm>
    <v-overlay :value="loadingRemedyById" style="z-index: 6">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="dialogPublish"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t("e_test.select_question_publish") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-radio-group v-model="radioGroup">
            <v-radio :label="$t('e_test.the_main_question')" :value="1" />
            <v-radio :label="$t('e_test.backup_question')" :value="0" />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loadingPublish"
            color="primary"
            outlined
            small
            @click="dialogPublish = false"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            :loading="loadingPublish"
            color="gradient-primary"
            dark
            depressed
            small
            @click="savePublish(true)"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import store from "@/store";
import moment from "moment/moment";
moment.locale("id");
import {
  listRemidi,
  removeRemedy,
  remedyById
} from "@/api/admin/academic/eTest";
import { unpublishQuestion, publishQuestion } from "@/api/admin/academic/eTest";

export default {
  components: {
    Pagination: () => import("@/components/Pagination"),
    AddEtest: () => import("../dialog/AddEtest"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.getListRemidi();
  },
  watch: {
    "table.search"(newValue) {
      this.table.search = newValue;
      this.getListRemidi();
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      dataTableMultiple: [],
      dialogPublish: false,
      loadingPublish: false,
      radioGroup: 1,
      contentModal: "",
      titleModal: "",
      modalConfirm: false,
      loadingConfirm: false,
      viewRemedy: false,
      etestClassRemedy: {},
      deleteConfirm: false,
      loadingDelete: false,
      openAddRemedy: false,
      detailMenu: [
        {
          icon: "mdi-eye-outline",
          name: "Detail"
        },
        {
          icon: "mdi-format-list-bulleted",
          name: this.$i18n.t("e_test.question")
        },
        { icon: "mdi-checkbox-marked-circle-outline", name: "Publish" },
        {
          icon: "mdi-trending-up",
          name: this.$i18n.t("e_test.statistics")
        },
        {
          icon: "mdi-account-edit",
          name: "Edit " + this.$i18n.t("e_test.chance")
        },
        {
          icon: "mdi-delete",
          name: this.$i18n.t("e_test.delete_remedy")
        }
      ],
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false
      },
      dataTable: {
        header: {},
        body: {
          data: []
        }
      },
      tableHeaders: [
        { text: "#", value: "number" },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.title"),
          value: "title"
        },
        {
          text: this.$i18n.t(
            "e_test.table_header.detail_subjects.release_date"
          ),
          value: "start"
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.end_date"),
          value: "end"
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.hours"),
          value: "hours"
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.duration"),
          value: "duration",
          width: 110
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.status"),
          value: "status"
        },
        {
          text: this.$i18n.t("app.action"),
          align: "center",
          sortable: false,
          value: "action",
          width: 100
        }
      ],
      loadingRemedyById: false,
      dataPublish: {}
    };
  },
  methods: {
    async getRemedyById(etestClass) {
      this.loadingRemedyById = true;
      try {
        const response = await remedyById({ etest_class: etestClass });
        if (response.data.code) {
          const item = response.data.data;
          // epoch to human date
          const dateRemedy = {
            startDate: moment
              .unix(moment(item.start).unix())
              .format("YYYY-MM-DD"),
            endDate: moment.unix(moment(item.end).unix()).format("YYYY-MM-DD"),
            startHours: moment.unix(moment(item.start).unix()).format("HH:mm"),
            endHours: moment.unix(moment(item.end).unix()).format("HH:mm")
          };
          // kurang title remedy dan description
          const moreHeader = {
            title: item.title,
            descriptionById: item.description,
            etest_class: etestClass,
            duration: item.duration,
            chance: item.chance,
            dateRemedy: dateRemedy
          };
          this.dataTable.header = { ...moreHeader, ...this.dataTable.header };
          this.viewRemedy = true;
          this.toggleAddEtest();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingRemedyById = false;
    },
    async deleteRemedy() {
      this.loadingDelete = true;
      let etestClasses = [];
      if (this.dataTableMultiple.length === 0) {
        etestClasses = [this.etestClassRemedy];
      } else {
        this.dataTableMultiple.map(item => etestClasses.push(item));
      }
      new Promise(async () => {
        await Promise.all(
          etestClasses.map(
            async etestClass => await this.singleDeleteRemedy(etestClass)
          )
        );
        this.dataTableMultiple = [];
        this.etestClassRemedy = {};
        this.loadingDelete = false;
        this.toggleDialogDelete();
      });
      // try {
      //   const response = await removeRemedy({
      //     etest_class: this.etestClassRemedy
      //   });
      //   if (response.data.code) {
      //     this.snackBar(true, response.data.message);
      //   } else {
      //     this.snackBar(false, response.data.message);
      //   }
      // } catch (error) {
      //   this.snackBar(false, error);
      // }
    },
    async singleDeleteRemedy(itemEtest) {
      try {
        const response = await removeRemedy({
          etest_class: itemEtest.etest_class
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.success_delete_remedy"));
        } else {
          this.snackBar(false, this.$i18n.t("e_test.failed_delete_remedy"));
        }
      } catch (error) {
        console.error("deleteEtest()\n", error);
      }
    },
    toggleDialogDelete() {
      if (this.deleteConfirm) this.getListRemidi();
      this.deleteConfirm = !this.deleteConfirm;
    },
    toggleAddEtest() {
      if (this.openAddRemedy) {
        this.getListRemidi();
        this.viewRemedy = false;
      }
      this.openAddRemedy = !this.openAddRemedy;
    },
    async getListRemidi() {
      this.table.loading = true;
      try {
        const response = await listRemidi({
          etest_class: this.$route.params.etestClass,
          ...this.table
        });
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    },
    async savePublish(isPublish) {
      const msg =
        isPublish === true
          ? "e_test.msg_success_publish_remedy"
          : "e_test.msg_unpublish_remedy";
      this.loadingConfirm = true;
      this.loadingPublish = true;
      try {
        let response = {};
        if (isPublish === true) {
          // question_selected (1 == main question || 0 == backup question)
          response = await publishQuestion({
            ...this.dataPublish,
            question_selected: this.radioGroup
          });
          if (response.data.code) {
            const d = response.data.data;
            this.$socket.emit("serverNotif", {
              recipient: d.recipient,
              data: d
            });
          }
        } else {
          response = await unpublishQuestion(this.dataPublish);
        }
        if (response.data.code) {
          this.getListRemidi();
          this.snackBar(true, this.$i18n.t(msg));
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.loadingConfirm = false;
        this.loadingPublish = false;
        console.error("savePublish()\n", error);
        this.snackBar(false, error);
      }
      this.loadingConfirm = false;
      this.loadingPublish = false;
      this.modalConfirm = false;
      this.dialogPublish = false;
      // reset data
      this.dataPublish = {};
      this.radioGroup = 1;
    },
    changePublish(data) {
      this.dataPublish = { etest_class: data.id };
      if (data.status === "DRAFT") {
        this.contentModal = this.$i18n.t("e_test.msg_publish_remedy.unpublish");
        this.titleModal =
          this.$i18n.t("app.cancel") +
          " " +
          this.$i18n.t("e_test.publish_remedy");
        this.modalConfirm = !this.modalConfirm;
      } else {
        this.dialogPublish = true;
      }
    },
    getRoute(item, index) {
      switch (index) {
        case 0:
          this.getRemedyById(item.etest_class);
          break;
        case 1:
          this.$router.push("/academic/etest-question/" + item.etest_class);
          break;
        case 2:
          this.changePublish({
            id: item.etest_class,
            status: item.status === "DRAFT" ? "PUBLISH" : "DRAFT"
          });
          break;
        case 3:
          this.$router.push(`/academic/statistics/${item.etest_class}`);
          break;
        case 4:
          this.$router.push(`/academic/view-chance/${item.etest_class}`);
          break;
        case 5:
          this.etestClassRemedy = item;
          this.toggleDialogDelete();
          break;
        default:
          this.$router.push("#");
          break;
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    getHours: date => moment.unix(moment(date).unix()).format("HH:mm"),
    getDate: (date, isEpoch) =>
      isEpoch
        ? moment.unix(moment(date).unix()).format("DD MMMM YYYY")
        : moment(date).format("DD MMMM YYYY"),
    paginateChange(page, limit) {
      this.table.limit = limit;
      this.table.page = page;
      this.getListRemidi();
    }
  }
};
</script>
<style lang="scss" scoped>
.dark-mode {
  background: #121212;
  border: 10px solid #424242;
}
.divider {
  border-right: 1px solid #e2e2e2;
}
</style>
